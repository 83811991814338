import io from "socket.io-client";
// const URL = "https://smashing-shrew-peaceful.ngrok-free.app";
// const URL = "https://development-dot-mess-ice-app.uc.r.appspot.com";
const MESSICE = "https://mess-ice-app.uc.r.appspot.com";

// Establish the socket connection.
export const socket = io(MESSICE, {
  autoConnect: false,
  transports: ["websocket"],
});
