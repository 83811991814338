import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
// import { Chart } from "react-google-charts";
import Chart from "../../../charts/charts";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";

import { useSelector } from "react-redux";
import translations from "../../../locals/translation";
import Swal from "sweetalert2";
import GlobalURL from "../../BaseUrl";

// export const data = [
//   ["Subscription", "Users"],
//   ["Plata", 38],
//   ["Oro", 58],
//   ["Diamante", 26],
// ];

const options = {
  title: "",
  legend: "none",
  pieSliceText: "label",
  backgroundColor: "transparent",
  titleTextStyle: {
    color: "#FEF6F2",
  },
  slices: {
    0: {
      offset: 0.1,
      color: "#25762E",
      link: "/digitalmanager",
    },
    1: {
      color: "#C37704",
      link: "/another/page",
    },
    2: {
      color: "#005EAA",
      link: "/yet/another/page",
    },
  },
};

const HomePage = () => {
  const [data, setData] = useState([]);

  const language = useSelector((state) => state.language.value);

  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  function handleClick() {
    window.location.href = "/digitalsection";
  }

  let navigate = useNavigate();

  async function getData() {
    // const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllVerifiedUser`;
    const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllDigitalManager`; // endpoint
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({ search: "" }),
      });

      // Check if the request was successful (status code 2xx)

      if (response.code === 401) {
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
        navigate("/login");
      }

      if (response.ok) {
        const responseData = await response.json();
        console.log("responseData.data: ", responseData.data.user);

        const temp = { gold: 0, platinum: 0, diamond: 0 };

        responseData.data.user.forEach((item) => {
          switch (item.plan_id) {
            case 1:
              temp.gold++;
              break;
            case 2:
              temp.gold++;
              break;
            case 3:
              temp.gold++;
              break;
            case 4:
              temp.gold++;
              break;
            case 5:
              temp.platinum++;
              break;
            case 6:
              temp.diamond++;
              break;
            default:
              break;
          }
        });

        const updatedData = [
          ["Subscription", "Users"],
          ["Plata", 5], // Update Platinum count
          ["Oro", 5], // Update Gold count
          ["Diamante", 5], // Update Diamond count
        ];

        setData(updatedData);
        console.log("updatedData: ", updatedData);
      } else {
        // If the server returns an error status code
        const errorData = await response.json();
      }
    } catch (error) {
      // Handle any network or other errors that may occur during the fetch
      console.error("Error:", error.message);
    }
  }

  //   const handlePageCount = async (e) => {

  //     const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllDigitalManager?page=${1}&limit=10`; // endpoint
  //     const token = localStorage.getItem('token');

  //     try {
  //         const response = await fetch(apiUrl, {
  //             method: 'GET',
  //             headers: {
  //                 'Content-Type': 'application/json',
  //                 'Authorization': `Bearer ${window.localStorage.getItem("token")}`,
  //                 "ngrok-skip-browser-warning": "69420",
  //             },
  //         });

  //         // Check if the request was successful (status code 2xx)
  //         if (response.code === 401) {
  //             Swal.fire(t('La sesión expiró, vuelva a iniciar sesión'));
  //         }
  //         if (response.ok) {
  //             const responseData = await response.json();

  //             setLeaders(responseData.data.user);
  //             console.log("leaders unverified", responseData.data.user);

  //             if (responseData.data.user.length < 10) {
  //                 setDisable(true);
  //             }
  //         } else {
  //             // If the server returns an error status code
  //             const errorData = await response.json();
  //             Swal.fire(t('Error del Servidor'));

  //         }
  //     } catch (error) {
  //         // Handle any network or other errors that may occur during the fetch
  //         console.error('Error:', error.message);
  //         Swal.fire(t('Error del Servidor'));
  //     }
  // }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="dashboard">
        <div className="dashboard-container">
          <Container fluid>
            <Row className="justify-content-md-center">
              <Col lg="3" md="12"></Col>

              <Col lg="6" md="12">
                <div className="dashboard-main">
                  <div className="dashboard-content">
                    <h1 className="main-title">
                      {t("PANEL DE CONTROL DE MODERADOR DE APLICACIÓN")}
                    </h1>
                    <div className="blue-add-button">
                      <button
                        className="blue-btn custom_marginRight"
                        onClick={() => {
                          navigate("/uploadambassador");
                        }}
                      >
                        {t("Agregar embajador")}
                      </button>
                    </div>
                    <div className="animate__animated animate__fadeIn">
                      <div className="dashboard-card-secs">
                        <Row className="justify-content-center container align-items-center container">
                          <Col
                            className="dashboardIconCol"
                            sm="4"
                            xs="6"
                            onClick={() => {
                              navigate("/proposalvote");
                            }}
                          >
                            <div className="control-card">
                              <div className="bg-effect">
                                <div className="card-img">
                                  <img src="./img/propuestas.png" alt="" />
                                </div>
                                <div className="card-body">
                                  <h4>{t("Propuestas & Votos")}</h4>
                                  <p>{t("Descripción")}</p>
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col
                            className="dashboardIconCol"
                            sm="4"
                            xs="6"
                            onClick={() => {
                              navigate("/debateroom");
                            }}
                          >
                            <div className="control-card">
                              <div className="bg-effect">
                                <div className="card-img">
                                  <img src="./img/chaticon.png" alt="" />
                                </div>
                                <div className="card-body">
                                  <h4>{t("Salón de Debates")}</h4>
                                  <p>{t("Descripción")}</p>
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col className="dashboardIconCol" sm="4" xs="6">
                            <div
                              className="control-card"
                              onClick={() => navigate("/billboard")}
                              style={{ cursor: "pointer" }}
                            >
                              <div className="bg-effect">
                                <div className="card-img">
                                  <img src="./img/cartelera.png" alt="" />
                                </div>
                                <div className="card-body">
                                  <h4>{t("Cartelera")}</h4>
                                  <p>{t("Descripción")}</p>
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col
                            className="dashboardIconCol"
                            sm="4"
                            xs="6"
                            onClick={() => {
                              navigate("/streaming");
                            }}
                          >
                            <div className="control-card">
                              <div className="bg-effect">
                                <div className="card-img">
                                  <img src="./img/streaming.png" alt="" />
                                </div>
                                <div className="card-body">
                                  <h4>{t("Streaming")}</h4>
                                  <p>{t("Descripción")}</p>
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col
                            className="dashboardIconCol"
                            sm="4"
                            xs="6"
                            onClick={() => {
                              navigate("/experiencepage");
                            }}
                          >
                            <div className="control-card">
                              <div className="bg-effect">
                                <div className="card-img">
                                  <img
                                    src="./img/VIVENCIASMESS_ICE.png"
                                    alt=""
                                  />
                                </div>
                                <div className="card-body">
                                  <h4>{t("Vivencias Mess Ice")}</h4>
                                  <p>Descripción</p>
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col className="dashboardIconCol" sm="4" xs="6">
                            <div
                              className="control-card"
                              onClick={() => navigate("/events")}
                              style={{ cursor: "pointer" }}
                            >
                              <div className="bg-effect">
                                <div className="card-img">
                                  <img src="./img/Eventos.png" alt="" />
                                </div>
                                <div className="card-body">
                                  <h4>{t("Eventos")}</h4>
                                  <p>{t("Descripción")}</p>
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col className="dashboardIconCol" sm="4" xs="6">
                            <div
                              className="control-card"
                              onClick={() => navigate("/multimedia")}
                              style={{ cursor: "pointer" }}
                            >
                              <div className="bg-effect">
                                <div className="card-img">
                                  <img src="./img/multimedia.png" alt="" />
                                </div>
                                <div className="card-body">
                                  <h4>{t("Multimedia")}</h4>
                                  <p>{t("Descripción")}</p>
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col className="dashboardIconCol" sm="4" xs="6">
                            <>
                              <div
                                className="control-card"
                                onClick={() => navigate("/digitalsection")}
                              >
                                {" "}
                                <div className="bg-effect">
                                  <div className="card-img">
                                    <img src="./img/AdminEquipo.png" alt="" />
                                  </div>
                                  <div className="card-body">
                                    <h4>{t("Administración")}</h4>
                                    <p>{t("Descripción")}</p>
                                  </div>
                                </div>
                              </div>
                            </>
                          </Col>

                          <Col
                            className="dashboardIconCol homepage-chart"
                            sm="4"
                            xs="6"
                          >
                            <Link
                              target="_blank"
                              to="/emails"
                              className="gmail-homepage-card"
                            >
                              <div className="control-card">
                                <div className="bg-effect">
                                  <div className="card-img">
                                    <img src="./img/ico_mail.svg" alt="" />
                                  </div>
                                  <div className="card-body">
                                    <h4>{t("Bandeja de Correo")}</h4>
                                    <p>{t("Descripción")}</p>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </Col>
                          <Col className="dashboardIconCol" sm="4" xs="6">
                            <div
                              className="control-card"
                              onClick={() => navigate("/store")}
                              style={{ cursor: "pointer" }}
                            >
                              <div className="bg-effect">
                                <div className="card-img-vstore">
                                  <img src="./img/store.png" alt="" />
                                </div>
                                <div className="card-body">
                                  <h4>{t("Tienda virtual")}</h4>
                                  <p>{t("Descripción")}</p>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col lg="3" md="12">
                <div className="team-list-char-sec">
                  <div
                    className="piechart-sec text-white"
                    hidden={false}
                    onClick={() => {
                      // navigate("/digitalsection");
                    }}
                  >
                    {data.length < 1 ? (
                      <div
                        className=""
                        style={{
                          height: "100px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div className="loading-adjust">
                          <div className="Loader d-flex justify-content-center align-items-center">
                            <div
                              className="spinner-border spinner-border my-11"
                              role="status"
                            >
                              <span className="sr-only"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="dashboardChart">
                        <Chart data={data} options={options} />
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontFamily: "Francois One",
                            "font-size": "15px",
                          }}
                        >
                          {t("DIRIGENTES DIGITALES")}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default HomePage;
