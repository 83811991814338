import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import "./style.css";
import Swal from "sweetalert2";
import { getAuth } from "firebase/auth";
import { RxEyeClosed } from "react-icons/rx";
import { IoEyeOutline } from "react-icons/io5";

import { useSelector } from "react-redux";
import translations from "../../locals/translation";
import GlobalURL from "../BaseUrl";

const ForgetPass = () => {
  const language = useSelector((state) => state.language.value);

  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const auth = getAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [wrongCredentials, setWrongCredentials] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = async () => {
    setLoading(true);
    if (username) {
      try {
        const response = await fetch(
          `${GlobalURL}/api/dev/app_moderator/forgotPassword`,
          {
            method: "POST",
            mode: "cors",
            body: JSON.stringify({
              email: username,
            }),
            headers: {
              "Content-Type": "application/json",
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        const data = await response.json();
        console.log("Data: ", data.code);
        if (data.code === 401) {
          setWrongCredentials(true);
          setLoading(false);
          setMessage("Email not found, correct the email and try again");
          return;
        }
        // Check the response status and handle accordingly
        if (!response.ok) {
          setLoading(false);
        }
        if (response.ok) {
          setLoading(false);

          Swal.fire(
            t(
              "Se ha enviado un correo electrónico con las instrucciones para restablecer la contraseña."
            )
          );
        }
      } catch (error) {
        if (
          error.code === "auth/invalid-email" ||
          error.code === "auth/wrong-password"
        ) {
          // Handle invalid email or wrong password error
          console.log("Invalid email or password.");
          setWrongCredentials(true);
          Swal.fire(t("El ID o la contraseña son incorrectos"));
          setLoading(false);
          return;
        }
        setLoading(false);
        // Handle errors during the authentication process
        if (error.code === "auth/invalid-email") {
          setLoading(false);
          Swal.fire(t("Error del Servidor"));
        } else if (
          error.code === "auth/user-not-found" ||
          error.code === "auth/wrong-password"
        ) {
          setLoading(false);
          setWrongCredentials(true);
          Swal.fire(t("El ID o la contraseña son incorrectos"));
        } else {
          setLoading(false);
          Swal.fire(t("Error del Servidor"));
        }
      }
    } else {
      setLoading(false);
      Swal.fire(t("Error del Servidor"));
      //authErr.innerText = "¡Campo requerido!";
    }
  };

  return (
    <>
      <div className="login-page">
        <div className="login-wrapper">
          <div className="animate__animated animate__fadeIn">
            <div className="login-container">
              <div className="logo-img">
                <img src="./img/logo.png" alt="" />
              </div>
              <div className="login-form-sec">
                <>
                  <div className="cust-form-control">
                    <div className="statement-telling">
                      ¿Olvidaste tu contraseña?
                    </div>
                    {!(message?.length == 0) && <div>{message}</div>}
                    <input
                      type="email"
                      className="form-input"
                      placeholder={t("Introduce tu correo electrónico")}
                      value={username}
                      name="username"
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}
                      style={{ borderColor: wrongCredentials ? "#b50000" : "" }}
                      onClick={() => {
                        setWrongCredentials(false);
                      }}
                      required
                    />
                  </div>
                  <div className="cust-form-control password-sec">
                    <NavLink className="forgot-pass-link" to="">
                      {" "}
                    </NavLink>
                  </div>

                  <div className="signup-btn-sec">
                    <span className="show-error w-75 mb-2 d-inline-block text-danger"></span>
                    <span className="log-btn">
                      <button
                        className="signup-btn"
                        onClick={handleSubmit}
                        disabled={loading}
                      >
                        {loading ? (
                          <div
                            className=""
                            style={{
                              height: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div className="loading-adjust">
                              <div className="Loader d-flex justify-content-center align-items-center">
                                <div
                                  className="spinner-border spinner-border-sm my-5"
                                  role="status"
                                >
                                  <span className="sr-only"></span>
                                </div>
                                <div className="my-5">
                                  {" "}
                                  &nbsp; {t("Cargando...")}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          "Enviar correo electrónico"
                        )}{" "}
                      </button>
                    </span>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPass;
