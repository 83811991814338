import React, { useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import "./style.css";
import Swal from "sweetalert2";
import { getAuth } from "firebase/auth";
import { RxEyeClosed } from "react-icons/rx";
import { IoEyeOutline } from "react-icons/io5";

import { useSelector } from "react-redux";
import translations from "../../locals/translation";
import { Input } from "@mui/material";

import GlobalURL from "../BaseUrl";

const ResetPassword = () => {
  let params = useParams();
  let tokenString = params?.token;
  let token = tokenString.match(/=(.+)/)[1];
  console.log("params: ", token);
  const language = useSelector((state) => state.language.value);

  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const auth = getAuth();
  const navigate = useNavigate();
  const [cpassword, setCpassword] = useState("");
  const [password, setPassword] = useState("");
  const [eyeOpen, seteyeOpen] = useState(false);
  const [wrongCredentials, setWrongCredentials] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = async () => {
    setLoading(true);
    setWrongCredentials(false);
    if (cpassword === password) {
      if (cpassword.length < 6 && password.length < 6) {
        setLoading(false);
        setWrongCredentials(true);
        setMessage("La contraseña debe tener al menos 6 caracteres.");
        return;
      }
      try {
        const response = await fetch(
          `${GlobalURL}/api/dev/app_moderator/reset-password`,
          {
            method: "POST",
            mode: "cors",
            body: JSON.stringify({
              new_password: cpassword,
              token: token,
            }),
            headers: {
              "Content-Type": "application/json",
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        const data = await response.json();
        console.log("Datos: ", data.code);
        if (data.code === 401) {
          setWrongCredentials(true);
          setLoading(false);
          setMessage("No se pudo restablecer tu contraseña");
          return;
        }
        // Verificar el estado de la respuesta y manejar en consecuencia
        if (!response.ok) {
          setLoading(false);
          setMessage("No se pudo restablecer tu contraseña");
        }
        if (response.ok) {
          setLoading(false);

          Swal.fire(t("Contraseña restablecida con éxito"));
          navigate("/login");
        }
      } catch (error) {
        if (
          error.code === "auth/invalid-email" ||
          error.code === "auth/wrong-password"
        ) {
          // Manejar error de correo electrónico no válido o contraseña incorrecta
          console.log("Correo electrónico o contraseña no válidos.");
          setWrongCredentials(true);
          Swal.fire(t("El ID o la contraseña son incorrectos"));
          setLoading(false);
          return;
        }
        setLoading(false);
        // Manejar errores durante el proceso de autenticación
        if (error.code === "auth/invalid-email") {
          setLoading(false);
          Swal.fire(t("Error del Servidor"));
        } else if (
          error.code === "auth/user-not-found" ||
          error.code === "auth/wrong-password"
        ) {
          setLoading(false);
          setWrongCredentials(true);
          Swal.fire(t("El ID o la contraseña son incorrectos"));
        } else {
          setLoading(false);
          Swal.fire(t("Error del Servidor"));
        }
      }
    } else {
      setLoading(false);
      setMessage("Las contraseñas no coinciden");
      return;
      //authErr.innerText = "¡Campo requerido!";
    }
  };

  return (
    <>
      <div className="login-page">
        <div className="login-wrapper">
          <div className="animate__animated animate__fadeIn">
            <div className="login-container">
              <div className="logo-img">
                <img src="../../img/logo.png" alt="" />
              </div>
              <div className="login-form-sec">
                <>
                  <div className="statement-telling">
                    Restablece tu contraseña
                  </div>
                  <div className="cust-form-control">
                    <input
                      type="text"
                      autoComplete="off"
                      autoCorrect="off"
                      className="form-input"
                      placeholder={t("Contraseña")}
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      style={{ borderColor: wrongCredentials ? "#b50000" : "" }}
                      onClick={() => {
                        setWrongCredentials(false);
                      }}
                      required
                    />
                  </div>
                  <div className="cust-form-control password-sec">
                    <NavLink
                      className="forgot-pass-link"
                      to=""
                      onClick={() => {
                        seteyeOpen(!eyeOpen);
                      }}
                    >
                      {" "}
                      {eyeOpen ? <IoEyeOutline /> : <RxEyeClosed />}
                    </NavLink>
                    <input
                      type={eyeOpen ? "text" : "password"}
                      className="form-input"
                      placeholder={t("Confirmar Contraseña")}
                      value={cpassword}
                      autoComplete="off"
                      autoCorrect="off"
                      onChange={(e) => {
                        const newCpassword = e.target.value; // Get the current input value
                        setCpassword(newCpassword); // Update cpassword state

                        if (newCpassword.length > password.length) {
                          setMessage("Las contraseñas no coinciden");
                          setWrongCredentials(true);
                        } else if (password !== newCpassword) {
                          setMessage("Las contraseñas no coinciden");
                          setWrongCredentials(true);
                        } else {
                          setMessage(""); // Clear the message if passwords match
                          setWrongCredentials(false);
                        }
                      }}
                      onClick={() => {
                        setWrongCredentials(false);
                      }}
                      style={{ borderColor: wrongCredentials ? "#b50000" : "" }}
                      required
                    />
                  </div>

                  <NavLink
                    className="forgot-pass-link"
                    id="forgetPass-word"
                    to={``}
                  >
                    {" "}
                    {t(message)}
                  </NavLink>

                  <div className="signup-btn-sec">
                    <span className="show-error w-75 mb-2 d-inline-block text-danger"></span>
                    <span className="log-btn">
                      <button
                        className="signup-btn"
                        onClick={handleSubmit}
                        disabled={loading}
                      >
                        {loading ? (
                          <div
                            className=""
                            style={{
                              height: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div className="loading-adjust">
                              <div className="Loader d-flex justify-content-center align-items-center">
                                <div
                                  className="spinner-border spinner-border-sm my-5"
                                  role="status"
                                >
                                  <span className="sr-only"></span>
                                </div>
                                <div className="my-5">
                                  {" "}
                                  &nbsp; {t("Cargando...")}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          "Entregar"
                        )}{" "}
                      </button>
                    </span>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
