import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./App.scss";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { getAuth } from "firebase/auth";
import { Provider } from "react-redux";
import store from "./redux/store";
import { useNavigate } from "react-router-dom";
import "animate.css";

const TokenRefreshLogic = () => {
  const auth = getAuth();
  // Your token refresh logic goes here

  async function createToken() {
    const refres̵hToken = localStorage.getItem("refresh-token");
    console.log("new token created; ", refres̵hToken);
    if (!refreshToken) {
      return;
    }
    try {
      const refreshedUser = await auth.currentUser.getIdToken(true);
      console.log("New Refresh user from Index ", refreshedUser);
      localStorage.setItem("token", refreshedUser);
    } catch (error) {
      console.error("Error refreshing access token:", error.message);
    }
  }
  const relogin = async () => {
    console.log(
      "Refresh token from IndexJs ",
      localStorage.getItem("refresh-token")
    );
    const refreshToken = localStorage.getItem("refresh-token");
    if (!refreshToken) {
      return;
    }
    try {
      const refreshedUser = await auth?.currentUser?.getIdToken(true);
      console.log("New Token from IndexJs", refreshedUser);
      localStorage.setItem("token", refreshedUser);
    } catch (error) {
      console.error("Error refreshing access token:", error.message);
    }
  };

  console.log(
    "Index Logic js executed:;;-----------------:;;-----------------:;;-----------------:;;-----------------:;;-----------------"
  );

  const refreshToken = localStorage.getItem("refresh-token");

  if (refreshToken) {
    // 1 create new token
    // 2 setInterval of 3seconds and relogin

    createToken();

    //   const intervalJS = setInterval(() => {
    // const refreshToken = localStorage.getItem("refresh-token");
    // if (!refreshToken) {
    //   clearInterval(intervalJS);
    //   return;
    // }
    //   relogin();
    // }, 3000); 3000000 // 50 minutes in milliseconds

    const intervalJS = setInterval(() => {
      const refreshToken = localStorage.getItem("refresh-token");
      if (!refreshToken) {
        clearInterval(intervalJS);
        return;
      }
      relogin();
    }, 3000000);
  }
};

TokenRefreshLogic();

ReactDOM.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID">
      <Suspense fallback="">
        <App />
      </Suspense>
    </GoogleOAuthProvider>
  </Provider>,
  document.getElementById("root")
);

// const refreshedUser = await user.getIdTokenResult(true);
