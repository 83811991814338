import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import "./style.css";
import { RiDeleteBin6Line } from "react-icons/ri";
import Swal from "sweetalert2";
import GlobalURL from "../BaseUrl";

import { useSelector } from "react-redux";
import translations from "../../locals/translation";

import { Swiper, SwiperSlide } from "swiper/react";
import { IoIosArrowDown } from "react-icons/io";
// import { Pagination, Navigation } from "swiper/modules";
import { useRef } from "react";
import SwiperComponent from "./SwiperComponent";

const Billboard = () => {
  const language = useSelector((state) => state.language.value);
  const dropdownRef = useRef(null);

  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const [deleteOption, setDeleteOption] = useState(false);
  const [banner, setBanner] = useState();
  const navigate = useNavigate();
  const [pageNum, setPageNum] = useState(2);
  const [showLoadM, setShowLoadM] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [originalData, setOriginalData] = useState([]);
  const [setshowSearch, setsetShowSearch] = useState(false);

  const [noData, setNoData] = useState(false);

  const [loading, setLoading] = useState(false);

  const [searchActive, setSearchActive] = useState(false);

  const [selectedOption, setSelectedOption] = useState("Cuadrícula");

  const [dropdown, setDropdown] = useState(false);
  const [homeBan, setHomeBan] = useState("store");
  const [homeBanShow, setHomeBanShow] = useState("HOME");

  const [page, setPage] = useState(1);

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleDelete = async (banner_id) => {
    Swal.fire({
      title: t("Estas seguro"),
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const token = localStorage.getItem("token");
        const endpoint = `${GlobalURL}/api/dev/app_moderator/banner/deleteBanner`;

        try {
          const response = await fetch(endpoint, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${JSON.parse(
                window.localStorage.getItem("token")
              )}`,
              // Add any other headers as needed
            },
            body: JSON.stringify({ banner_id }),
          });

          if (!response.ok) {
            // Handle non-successful response
            Swal.fire(t("Server Error"));
          }
          if (response.code === 401) {
            Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
            navigate("/login");
          }
          if (response.ok) {
            Swal.fire({
              title: t("¡Eliminado!"),
              text: "",
              icon: "success",
            });
          }
        } catch (error) {
          // Handle errors during the fetch operation
          console.error("Error during fetch:", error);
          throw error; // Optionally rethrow the error
        }
      }
    });
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdown(false);
    }
  };

  // const handleLoadMore = async () => {
  //     setPageNum(pageNum + 1);
  //     try {
  //         const loadStatus = document.querySelector(".Loader");
  //         fetch(`${GlobalURL}/api/dev/app_moderator/banner/getAllBanner`, {
  //             method: "GET",
  //             mode: "cors",
  //             headers: {
  //                 "Content-Type": "application/json",
  //                 'Authorization': `Bearer ${window.localStorage.getItem("token")}`,
  //                 "ngrok-skip-browser-warning": "69420",
  //             },

  //         })
  //             .then(async (response) => {
  //                 console.log("errr: ", response)
  //                 if (response.status === 404) {
  //                     setNoData(true);
  //                     console.log("status: ", response)
  //                 }
  //                 if (response.code === 401) {
  //                     Swal.fire(t('La sesión expiró, vuelva a iniciar sesión'));
  //                 }
  //                 if (response.status === 403) {
  //                     // await refreshFirebaseToken();
  //                     setNoData(true);
  //                     console.log("statuss: ", response)
  //                 }
  //                 else if (response.status === 200) {
  //                     console.log(response);
  //                     return response.json();
  //                 }
  //             })
  //             .then(res => {
  //                 console.log("ress: ", res.data);
  //                 if (res.data.length < 20) {
  //                     setShowLoadM(false);
  //                 }
  //                 setBanner(prevBanner => [...prevBanner, ...res.data]);
  //                 setOriginalData(prevBanner => [...prevBanner, ...res.data]);

  //             })
  //             .catch((err) => {
  //                 setNoData(true);
  //                 console.log("error occured: ", err);
  //             });
  //     } catch (error) {

  //     }
  // }

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
    const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
      date
    );

    // Extract day, month, and year components
    const [day, month, year] = formattedDate.split("/");

    // Format as DD/MM/YY
    const formattedDateDDMMYY = `${day}/${month}/${year.slice(-2)}`;

    return formattedDateDDMMYY;
  }

  const handleSearch = (e) => {
    const dataTemp = originalData;
    setBanner(originalData);
    setSearchTerm(e.target.value);
    console.log("Search Term: ", e.target.value);
    if (e.target.value === "") {
      setBanner(originalData);
      return;
    }
    const result = dataTemp.filter(
      (item) => item.title.toLowerCase().includes(e.target.value)
      // Object.values(item.title).some(
      //     (value) => typeof value === 'string' && value.toLowerCase().includes(e.target.value)
      // )
    );
    console.log("result: ", result);

    if (result.length === 0) {
      console.log("Empty Arry executed:  ");
      return;
    }
    setBanner(result);
  };

  const filterData = (homBa) => {
    console.log("homBa: ", homBa);
    const data = originalData.filter((item, key) => item.banner_type === homBa);
    console.log("filtered data: ", data);
    setBanner(data);
  };

  const fetchData = async () => {
    console.log("UE 1 executed: ");
    // const loadStatus = document.querySelector(".Loader");

    try {
      const response = await fetch(
        `${GlobalURL}/api/dev/app_moderator/banner/getAllBanner`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      if (response.status === 404) {
        setNoData(true);
        console.log("status: ", response);
      }

      if (response.status === 401) {
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
      }

      if (response.status === 403) {
        // await refreshFirebaseToken();
        setNoData(true);
        console.log("statuss: ", response);
      }

      if (response.status === 200) {
        const data = await response.json();
        console.log("ress of banner: ", data.data);
        const filteredData = data.data.filter(
          (item) => item.banner_type === "home"
        );
        setBanner(filteredData);
        setOriginalData(data.data);
      }
    } catch (error) {
      setNoData(true);
      console.error("Error occurred: ", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="dashboard bb-page">
        <div>
          <div className="billboard-page mt-5">
            <Container fluid>
              <Row>
                <Col className="first-sec" lg="3" md="12"></Col>
                <Col className="second-sec" lg="6" md="12">
                  <>
                    <div className="news-header">
                      <div className="back-sec">
                        <img
                          src="./img/volver.png"
                          alt=""
                          onClick={() => {
                            navigate("/homepage");
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <h3 className="main-title">{t("Cartelera")}</h3>
                      <div className="search-icon" style={{ display: "flex" }}>
                        {searchActive && (
                          <div className="searchBarInput">
                            <input
                              className="search-tag-bar"
                              placeholder={t("Buscar")}
                              value={searchTerm}
                              onChange={(e) => {
                                handleSearch(e);
                              }}
                            />
                          </div>
                        )}
                        <FiSearch
                          className="search-icon-click"
                          onClick={() => {
                            setSearchActive(!searchActive);
                          }}
                        />
                      </div>
                    </div>
                    <div className="grid-list-sec widthChange">
                      <div className="radio-btns-sec">
                        <div className="left-radios-sec">
                          <div
                            className={`radio-sec ${
                              selectedOption === "Lista" ? "selected" : ""
                            }`}
                          >
                            <input
                              type="radio"
                              id="test1"
                              name="radio-group"
                              value={t("Cuadrícula")}
                              checked={selectedOption === "Cuadrícula"}
                              onChange={handleRadioChange}
                            />
                            <label htmlFor="test1">{t("Cuadrícula")}</label>
                          </div>
                          <div
                            className={`radio-sec ${
                              selectedOption === "Lista" ? "selected" : ""
                            }`}
                          >
                            <input
                              type="radio"
                              id="test2"
                              name="radio-group"
                              value={t("Lista")}
                              checked={selectedOption === "Lista"}
                              onChange={handleRadioChange}
                            />
                            <label htmlFor="test2">{t("Lista")}</label>
                          </div>
                        </div>
                      </div>
                      <div className="div-with-drop">
                        <p
                          className="tags-option-insignia"
                          style={{ textTransform: "uppercase" }}
                        >
                          {homeBanShow === "HOME" ? t("HOME") : t("TIENDA")}

                          <div className="down-arrow">
                            <IoIosArrowDown
                              onClick={() => {
                                setDropdown(!dropdown);
                              }}
                              style={{
                                width: "36px",
                                height: "25px",
                                marginTop: "-17px",
                                marginBottom: "-17px",
                                marginRight: "-17px",
                                marginLeft: "7px",
                                color: "#ffffff5e",
                              }}
                            />
                          </div>
                        </p>
                        {dropdown && (
                          <div className="dropdown-1" ref={dropdownRef}>
                            <p
                              className="dropdown-p-tag"
                              onClick={() => {
                                setHomeBan("home");
                                setHomeBanShow("HOME");
                                filterData("home");
                                setDropdown(!dropdown);
                              }}
                            >
                              {t("HOME")}
                            </p>
                            <p
                              className="dropdown-p-tag"
                              onClick={() => {
                                setHomeBan("store");
                                setHomeBanShow("TIENDA");
                                filterData("store");
                                setDropdown(!dropdown);
                              }}
                            >
                              {t("TIENDA")}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="div-with-drop"></div>
                      <div className="div-with-drop"></div>
                    </div>
                    <div className="billboard-sec bboardSec">
                      {loading ? (
                        <div
                          className=""
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="loading-adjust">
                            <div className="Loader d-flex justify-content-center align-items-center">
                              <div
                                className="spinner-border spinner-border-sm my-5"
                                role="status"
                              >
                                <span className="sr-only"></span>
                              </div>
                              <div className="my-5">
                                {" "}
                                &nbsp; {t("Cargando...")}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : noData ? (
                        <div className="not-available-notice">
                          {t("No hay ningún cartel disponible aquí.")}
                        </div>
                      ) : (
                        <>
                          {selectedOption === "Cuadrícula" && (
                            <div className="cuadricula-content">
                              {banner ? (
                                <div className="billboard-sec-inner2">
                                  <Row>
                                    <Col className="blankDiv" xs lg="4"></Col>

                                    <Col
                                      xs
                                      lg="4"
                                      className="current-banner rounded"
                                    >
                                      {banner?.length === 0 ? (
                                        <p
                                          style={{
                                            background: "black",
                                            margin: "1rem",
                                            padding: "1rem",
                                            fontSize: "14px",
                                          }}
                                        >
                                          {"No data to show"}
                                        </p>
                                      ) : (
                                        <SwiperComponent banner={banner} />
                                      )}
                                    </Col>

                                    <Col className="blankDiv" xs lg="4"></Col>

                                    <Col xs lg="12">
                                      <Row>
                                        {banner.length > 3 &&
                                          banner.map((item, i) => {
                                            if (i < banner.length - 3) {
                                              return (
                                                <Col xs lg="4">
                                                  <div
                                                    className="myTeamBanner billboard-cards animate__animated animate__fadeIn"
                                                    key={i}
                                                  >
                                                    <div
                                                      className="card-heading"
                                                      onClick={() =>
                                                        navigate(
                                                          "/editbillboard",
                                                          {
                                                            state:
                                                              banner[i + 3],
                                                          }
                                                        )
                                                      }
                                                    >
                                                      <h3>
                                                        {banner[i + 3].title}
                                                      </h3>
                                                    </div>
                                                    <div className="card-img">
                                                      {/* <img src={banner[i + 1].imageUrl} alt="" onClick={() => navigate('/editbillboard', { state: banner[i + 1] })} /> */}
                                                      {banner[
                                                        i + 3
                                                      ].imageUrl.endsWith(
                                                        ".mp4"
                                                      ) ? (
                                                        <video
                                                          className="video-grid-view"
                                                          muted
                                                          autoPlay={false}
                                                          onClick={() =>
                                                            navigate(
                                                              "/editbillboard",
                                                              {
                                                                state:
                                                                  banner[i + 3],
                                                              }
                                                            )
                                                          }
                                                        >
                                                          <source
                                                            src={
                                                              banner[i + 3]
                                                                .imageUrl
                                                            }
                                                            type="video/mp4"
                                                          />
                                                          {t(
                                                            "Su navegador no soporta la etiqueta de vídeo."
                                                          )}
                                                        </video>
                                                      ) : (
                                                        <img
                                                          src={
                                                            banner[i + 3]
                                                              .imageUrl
                                                          }
                                                          alt=""
                                                          onClick={() =>
                                                            navigate(
                                                              "/editbillboard",
                                                              {
                                                                state:
                                                                  banner[i + 1],
                                                              }
                                                            )
                                                          }
                                                        />
                                                      )}
                                                    </div>
                                                  </div>
                                                </Col>
                                              );
                                            }
                                          })}
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              ) : (
                                <div
                                  className=""
                                  style={{
                                    height: "500px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div className="loading-adjust">
                                    <div className="Loader d-flex justify-content-center align-items-center">
                                      <div
                                        className="spinner-border spinner-border-sm my-5"
                                        role="status"
                                      >
                                        <span className="sr-only"></span>
                                      </div>
                                      <div className="my-5">
                                        {" "}
                                        &nbsp; {t("Cargando...")}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                          {selectedOption === "Lista" && (
                            <div className="lista-content list_board">
                              {banner ? (
                                <div className="billboard-sec-inner2">
                                  <div className="lista-titles">
                                    <div className="lista-name">
                                      {t("Nombre")}
                                    </div>
                                    <div className="lista-creation">
                                      {t("Fecha de Creación")}
                                    </div>
                                    <div className="lista-modification">
                                      {t("Fecha de Modificación")}
                                    </div>
                                    {/* <div className='lista-right'> {deleteOption ? 'Eliminar' : ''} </div> */}
                                  </div>

                                  {banner.slice(0, 3).map((item, i) => (
                                    <div
                                      className="current-banner rounded"
                                      onClick={() =>
                                        navigate("/editbillboard", {
                                          state: item,
                                        })
                                      }
                                    >
                                      <div className="myTeamBanner billboard-cards animate__animated animate__fadeIn">
                                        <div className="lista-name ps-0">
                                          <div className="card-img">
                                            {/* <img src={banner[0].imageUrl} alt="" /> */}
                                            {item.imageUrl.endsWith(".mp4") ? (
                                              <video
                                                autoPlay={false}
                                                onClick={() =>
                                                  navigate("/editbillboard", {
                                                    state: item,
                                                  })
                                                }
                                              >
                                                <source
                                                  src={item.imageUrl}
                                                  type="video/mp4"
                                                />
                                                {t(
                                                  "Su navegador no soporta la etiqueta de vídeo."
                                                )}
                                              </video>
                                            ) : (
                                              <img
                                                src={item.imageUrl}
                                                alt=""
                                                onClick={() =>
                                                  navigate("/editbillboard", {
                                                    state: item,
                                                  })
                                                }
                                              />
                                            )}
                                          </div>
                                          <div className="card-heading">
                                            <h3>{item.title}</h3>
                                          </div>
                                        </div>

                                        <div className="lista-creation">
                                          <div className="card-heading">
                                            <p>{formatDate(item.createdAt)}</p>
                                          </div>
                                        </div>
                                        <div className="lista-modification">
                                          <div className="card-heading">
                                            <p>{formatDate(item.createdAt)}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}

                                  {banner.length > 3 &&
                                    banner.map((item, i) => {
                                      if (i >= 3) {
                                        return (
                                          <div
                                            className="myTeamBanner billboard-cards animate__animated animate__fadeIn"
                                            onClick={() =>
                                              navigate("/editbillboard", {
                                                state: banner[i],
                                              })
                                            }
                                            key={i}
                                          >
                                            <div className="lista-name ps-0">
                                              <div className="card-img">
                                                {/* <img src={item.imageUrl} alt="" /> */}
                                                {item.imageUrl.endsWith(
                                                  ".mp4"
                                                ) ? (
                                                  <video
                                                    onClick={() =>
                                                      navigate(
                                                        "/editbillboard",
                                                        {
                                                          state: item,
                                                        }
                                                      )
                                                    }
                                                  >
                                                    <source
                                                      src={item.imageUrl}
                                                      type="video/mp4"
                                                    />
                                                    {t(
                                                      "Su navegador no soporta la etiqueta de vídeo."
                                                    )}
                                                  </video>
                                                ) : (
                                                  <img
                                                    src={item.imageUrl}
                                                    alt=""
                                                    onClick={() =>
                                                      navigate(
                                                        "/editbillboard",
                                                        {
                                                          state: item,
                                                        }
                                                      )
                                                    }
                                                  />
                                                )}
                                              </div>
                                              <div className="card-heading">
                                                <h3>{item.title}</h3>
                                              </div>
                                            </div>
                                            <div className="lista-creation">
                                              <div className="card-heading">
                                                <p>
                                                  {formatDate(item.createdAt)}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="lista-modification">
                                              <div className="card-heading">
                                                <p>
                                                  {formatDate(item.createdAt)}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      } else {
                                        return null;
                                      }
                                    })}
                                </div>
                              ) : (
                                <div
                                  className=""
                                  style={{
                                    height: "500px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div className="loading-adjust">
                                    <div className="Loader d-flex justify-content-center align-items-center">
                                      <div
                                        className="spinner-border spinner-border-sm my-5"
                                        role="status"
                                      >
                                        <span className="sr-only"></span>
                                      </div>
                                      <div className="my-5">
                                        {" "}
                                        &nbsp; {t("Cargando...")}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      )}

                      <div className="billboard-btn-sec">
                        <button
                          className="green-btn"
                          onClick={() => {
                            navigate("/addbillboard");
                          }}
                        >
                          {t("Agregar Post")}
                        </button>
                        {/* <button className='red-btn' onClick={() => { setDeleteOption(!deleteOption) }}>Eliminar Post</button> */}
                      </div>
                    </div>
                  </>
                </Col>
                <Col className="third-sec" lg="3" md="12"></Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default Billboard;
