import React from "react";
import { Chart } from "react-google-charts"; // Assuming you're using Google Charts library

function CustomChart({ data, options }) {
  return (
    <Chart
      chartType="PieChart" // You can also make this dynamic by passing as a prop
      data={data}
      className="animate__animated animate__fadeIn"
      options={options}
      width={"300px"} // Default width is 300px if not provided
      height={"300px"} // Default height is 300px if not provided
    />
  );
}

export default CustomChart;
